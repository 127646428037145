<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <v-menu transition="slide-x-transition">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="ma-2" v-on="on">
          Filtrar por Unidad de Negocio
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="n in 5" :key="n" link>
          <v-list-item-title v-text="'Franquicia ' + n" />
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu transition="slide-x-transition">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="ma-2" v-on="on"> Filtrar por Departamento </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="n in 5" :key="n" link>
          <v-list-item-title v-text="'Departamento ' + n" />
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu transition="slide-x-transition">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="ma-2" v-on="on"> Filtrar por Usuario </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="n in 5" :key="n" link>
          <v-list-item-title v-text="'Usuario ' + n" />
        </v-list-item>
      </v-list>
    </v-menu>

    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-cell-click="onCellClick"
            :group-options="{
              enabled: true,
              collapsable: false,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'average'">
                <span style="font-weight: bold; color: blue">{{
                  props.row.average
                }}</span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </v-col>
      </v-row>
    </v-container>

    <modal name="evaluationModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Item</th>
                <th class="text-left">Puntaje</th>
                <th class="text-left">Peso interno</th>
                <th class="text-left">Resultado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.item">
                <td>{{ item.item }}</td>
                <td>{{ item.point }}</td>
                <td>{{ item.minimun }}</td>
                <td>{{ item.result }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            dark
            color="#5e72e4"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            dark
            color="#5e72e4"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px"
            color="#5e72e4"
            dark
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="#5e72e4"
            dark
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table'

  export default {
    components: { VueGoodTable },
    data: () => ({
      menuItems: [
        {
          id: 2,
          name: 'Formulacion de evaluaciónes ',
          icon: 'mdi-account-group',
          link: '/administrative/human-talent/positions',
        },
        {
          id: 3,
          name: 'Supervisiones ',
          icon: 'mdi-badge-account',
          link: '/administrative/human-talent/positions-roles',
        },
        {
          id: 4,
          name: 'Penalidades',
          icon: 'mdi-account-multiple-check-outline',
          link: '/administrative/human-talent/process',
        },
        {
          id: 5,
          name: 'Estadisticas',
          icon: 'mdi-gauge-full',
          link: '/administrative/human-talent/performance',
        },
      ],
      selected: [2],
      navigationElements: [
        {
          text: 'Control y Supervision',
          disabled: false,
          name: 'business/supervision-control/Dashboard',
          href: '/business/supervision-control/admin-panel',
        },
        {
          text: 'Sede Caracas',
          disabled: true,
        },
      ],
      items: [
        {
          action: '15 min',
          headline: 'Franquicia: Sede SUR',
          subtitle: `Evaluador: Miguel Fernandez`,
          title: 'evaluación de Puntos de venta',
        },
      ],
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      itemsPerPage: 4,
      columns: [
        {
          label: 'Franquicia',
          field: 'name',
        },
        {
          label: 'E1',
          field: 'e1',
          type: 'number',
        },
        {
          label: 'E2',
          field: 'e2',
          type: 'number',
        },
        {
          label: 'E3',
          field: 'e3',
          type: 'number',
        },
        {
          label: 'E4',
          field: 'e4',
          type: 'number',
        },
        {
          label: 'E5',
          field: 'e5',
          type: 'number',
        },
        {
          label: 'Promedio',
          field: 'average',
          type: 'number',
        },
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Franquicia 1', // this is the label that'll be used for the header
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Infraetructura',
              e1: '67%',
              e2: '90%',
              e3: '-',
              e4: '99%',
              e5: '89%',
              average: '79%',
            },
            {
              name: 'Imagen',
              e1: '67%',
              e2: '90%',
              e3: '-',
              e4: '99%',
              e5: '89%',
              average: '79%',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Franquicia 2', // this is the label that'll be used for the header
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Infraetructura',
              e1: '67%',
              e2: '90%',
              e3: '-',
              e4: '99%',
              e5: '89%',
              average: '79%',
            },
          ],
        },
      ],
      value: '',
      events: [],
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      names: [
        'Meeting',
        'Holiday',
        'PTO',
        'Travel',
        'Event',
        'Birthday',
        'Conference',
        'Party',
      ],
      desserts: [
        {
          item:
            'La fachada del POS se encuentra limpia, libre de rayaduras, maltratos, daños, etc',
          point: 12,
          minimun: 35,
          result: 43,
        },
        {
          item:
            'Las paredes internas y externas del POS se encuentran limpias, con su recubrimiento en buen estado ',
          point: 12,
          minimun: 35,
          result: 43,
        },
      ],
    }),
    methods: {
      getEvents({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)

        events.push({
          name: 'evaluación de puntos de Venta',
          start: min,
          end: max,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        })

        this.events = events
      },
      getEventColor(event) {
        return event.color
      },
      onCellClick(params) {
        this.$modal.show('evaluationModal')
      },
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>
<style lang="scss" scoped>
  .orgchart .node .title {
    font-weight: 400;
  }

  .travel-map {
    height: 400px;
  }
</style>
